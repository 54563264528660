<template>
	<div
		class="hero"
		:style="{
			backgroundImage: `url('${require('@/assets/' + heroImage + '')}')`,
		}"
	>
		<div class="image-overlay"></div>
		<h3 class="hero-text">{{ heroText }}</h3>
	</div>
</template>

<style lang="scss" scoped>
.hero {
	width: 100%;
	height: 100vh;
	position: relative;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	margin-bottom: 0;
	transition: all 750ms;
	animation-name: heroAnim;
	animation-delay: 400ms;
	animation-duration: 1800ms;
	animation-timing-function: ease;
	animation-fill-mode: forwards;
  box-shadow: 0 0 50px #000;

  z-index: 99;

	.hero-text {
    line-height: 0.9;
    color: white;
		font-size: 4rem;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) TranslateY(-50%);
		text-align: center;
    font-family: "Roboto Slab";
    font-weight: 600; /* Font weight doesnt work */
    text-shadow: 0 0 50px #00000070;
	}

	.image-overlay {
		background-color: transparent;
		opacity: 0;
		height: 100%;
		width: 100%;
		animation-name: overlayAnim;
		animation-delay: 400ms;
		animation-duration: 1800ms;
		animation-timing-function: ease;
		animation-fill-mode: forwards;
	}

	@keyframes heroAnim {
		0% {
			height: 100vh;
		}
		100% {
			height: 50vh;
		}
	}

	@keyframes overlayAnim {
		0% {
			background-color: #000;
			opacity: 0;
		}
		100% {
			background-color: #000;
			opacity: 0.5;
		}
	}
}
</style>

<script>
export default {
	name: "SubPageHero",
	props: {
		heroImage: String,
		heroText: String,
	},
};
</script>
