<template>

  <div class="contact">
    <subPageHero
        heroImage="images/Human_holding_bread.jpeg"
        heroText="Over ons"
    />

    <main-container>

      <SectionTitleContainer :title="$root.t('Het team')" light/>

      <ContentContainer class="mb-6" :content="{
        text: 'Het personeel van \'t Broodhuys bestaat uit een vaste kern die vanaf het begin af aan de ontwikkeling meegemaakt heeft. <br/><br/>'+
'Er is een vast team banketbakkers en broodbakkers die in wisseldiensten ambachtelijke producten maakt. <br/>Daarnaast is ’t Broodhuys een leerbedrijf en zijn er diverse stagiaires. <br/><br/>'+
'In de winkels staat een gezellig team klaar voor de klanten. <br/>De winkelmedewerkers zijn op alle locaties inzetbaar, maar over het algemeen staat er een vast team per locatie.\n'+
'Een vertrouwd gezicht in de winkel is voor de klanten erg fijn.',
      }" light/>

      <section class="has-background-white">

        <SectionTitleContainer :title="$root.t('Bezorgservice')"/>

        <ContentContainer :content="{
          image: 'images/customer/broodhuys__ok.jpg'
        }" reverse/>

        <ContentContainer :content="{
          text: 'Voor de omgeving Benningbroek en omstreken heeft bakker ’t Broodhuys een broodbus ter beschikking. Via whatsapp of bellen is het mogelijk om gebak of brood te bestellen. De broodbus gaat langs alle klanten en bedrijven om de bestellingen dagelijks vers af te leveren. <br/><br/>'+
'Ook in de streek kunnen de verse broden en gebak bezorgt worden. Maar u kunt ook bestellen en afhalen in de winkel, zo bent u ook tegen sluitingstijd verzekerd van een dagvers broodje of taart voor bij de koffie.',
        }" dark/>

      </section>

      <section class="">

        <SectionTitleContainer :title="$root.t('Meerdere vestigingen')" light/>

        <ContentContainer :content="{
          text: 'Er zijn vier winkellocaties van de bakkerij.',
        }" light>
        </ContentContainer>

        <div class="columns mb-6">
          <div class="column is-10 is-10-mobile is-offset-1 is-offset-1-mobile">
            <div class="columns">
              <div class="column is-6">
                <div class="box is-clickable" @click="flyTo(location.coordinates)" v-for="location in locations" :key="location.coordinates[0]">
                  <div class="block mb-2">
                    <p class="is-size-4 is-bold">{{ location.name }}</p>
                  </div>
                  <div class="block">
                    <p class="">{{ location.address }}</p>
                    <p class=""><a :href="`tel:${location.phone}`">{{ location.phone }}</a></p>
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <MglMap
                    :accessToken="accessToken"
                    :mapStyle="mapStyle"
                    @load="onMapLoad"
                    @dragend="log"
                >
                  <mgl-marker @click="flyTo(location.coordinates)" color="black" v-for="location in locations" :coordinates="location.coordinates" :key="location.coordinates[0]"/>
                </MglMap>
              </div>
            </div>

          </div>
        </div>


        <div class="columns mb-6">
          <div class="column is-10 is-10-mobile is-offset-1 is-offset-1-mobile">

            <div class="columns mt-3">

              <div class="column is-4-tablet is-12-mobile p-4">

                <img :src="require('@/assets/images/customer/Enkhuizen.jpg')" class="store-image"/>

              </div>

              <div class="column is-4-tablet is-12-mobile p-4">

                <img :src="require('@/assets/images/customer/Hoogkarspel.jpg')" class="store-image"/>

              </div>

              <div class="column is-4-tablet is-12-mobile p-4">

                <img :src="require('@/assets/images/customer/Hem.jpg')" class="store-image"/>

              </div>

            </div>

          </div>

        </div>




          <!--          maps -->



      </section>

    </main-container>




  </div>
</template>

<style scoped lang="scss">
.hero {
  background-image: url("../assets/images/Human_holding_bread.jpeg");
}

.mgl-map-wrapper{
  width: 100%;
  min-height: 400px;
  height: 100%;
  border-radius: 7px;
  overflow: hidden;
}
</style>

<script>
import subPageHero from "../components/SubPageHero";
import MainContainer from "@/components/Container/MainContainer";
// import TextContainer from "@/components/Content/TextContainer";
import ContentContainer from "@/components/Container/ContentContainer";
import SectionTitleContainer from "@/components/Container/SectionTitleContainer";
import Mapbox from "mapbox-gl";
import {MglMap, MglMarker} from "vue-mapbox";

export default {
  name: "Contact",
  components: {
    SectionTitleContainer,
    ContentContainer,
    // TextContainer,
    MainContainer,
    subPageHero,
    MglMap,
    MglMarker
  },

  data() {
    return {
      accessToken: 'pk.eyJ1Ijoia29ubmVjdGtpdCIsImEiOiJja2dudzZ4YXkyNWk0MzB0ZWdhc2dpZ3lqIn0.6LIo-Uo-A7CxvNCYYNQUZw', // your access token. Needed if you using Mapbox maps
      mapStyle: 'mapbox://styles/mapbox/streets-v11',
      locations: []
    };
  },

  created() {
    // let self = this;

    this.mapbox = Mapbox;
    this.locations = this.$root.stores;

  },

  mounted() {
    // let self = this;



  },

  beforeDestroy() {
    // let self = this;

  },

  methods: {

    logEvent(event) {
      console.log(event)
    },
    log(msg) {
      console.log(msg)
    },

    async onMapLoad(event) {

      this.mapActions = event.component.actions;

      await this.mapActions.flyTo({
        center: [5.220080, 52.687321],
        // 52.687321, 5.225080
        zoom: 11,
        speed: 2
      })
    },

    async flyTo(coordinates) {
      await this.mapActions.flyTo({
        center: coordinates,
        zoom: 15,
        speed: 2
      });
    }
  },
};
</script>
