var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact"},[_c('subPageHero',{attrs:{"heroImage":"images/Human_holding_bread.jpeg","heroText":"Over ons"}}),_c('main-container',[_c('SectionTitleContainer',{attrs:{"title":_vm.$root.t('Het team'),"light":""}}),_c('ContentContainer',{staticClass:"mb-6",attrs:{"content":{
        text: 'Het personeel van \'t Broodhuys bestaat uit een vaste kern die vanaf het begin af aan de ontwikkeling meegemaakt heeft. <br/><br/>'+
'Er is een vast team banketbakkers en broodbakkers die in wisseldiensten ambachtelijke producten maakt. <br/>Daarnaast is ’t Broodhuys een leerbedrijf en zijn er diverse stagiaires. <br/><br/>'+
'In de winkels staat een gezellig team klaar voor de klanten. <br/>De winkelmedewerkers zijn op alle locaties inzetbaar, maar over het algemeen staat er een vast team per locatie.\n'+
'Een vertrouwd gezicht in de winkel is voor de klanten erg fijn.',
      },"light":""}}),_c('section',{staticClass:"has-background-white"},[_c('SectionTitleContainer',{attrs:{"title":_vm.$root.t('Bezorgservice')}}),_c('ContentContainer',{attrs:{"content":{
          image: 'images/customer/broodhuys__ok.jpg'
        },"reverse":""}}),_c('ContentContainer',{attrs:{"content":{
          text: 'Voor de omgeving Benningbroek en omstreken heeft bakker ’t Broodhuys een broodbus ter beschikking. Via whatsapp of bellen is het mogelijk om gebak of brood te bestellen. De broodbus gaat langs alle klanten en bedrijven om de bestellingen dagelijks vers af te leveren. <br/><br/>'+
'Ook in de streek kunnen de verse broden en gebak bezorgt worden. Maar u kunt ook bestellen en afhalen in de winkel, zo bent u ook tegen sluitingstijd verzekerd van een dagvers broodje of taart voor bij de koffie.',
        },"dark":""}})],1),_c('section',{},[_c('SectionTitleContainer',{attrs:{"title":_vm.$root.t('Meerdere vestigingen'),"light":""}}),_c('ContentContainer',{attrs:{"content":{
          text: 'Er zijn vier winkellocaties van de bakkerij.',
        },"light":""}}),_c('div',{staticClass:"columns mb-6"},[_c('div',{staticClass:"column is-10 is-10-mobile is-offset-1 is-offset-1-mobile"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-6"},_vm._l((_vm.locations),function(location){return _c('div',{key:location.coordinates[0],staticClass:"box is-clickable",on:{"click":function($event){return _vm.flyTo(location.coordinates)}}},[_c('div',{staticClass:"block mb-2"},[_c('p',{staticClass:"is-size-4 is-bold"},[_vm._v(_vm._s(location.name))])]),_c('div',{staticClass:"block"},[_c('p',{},[_vm._v(_vm._s(location.address))]),_c('p',{},[_c('a',{attrs:{"href":("tel:" + (location.phone))}},[_vm._v(_vm._s(location.phone))])])])])}),0),_c('div',{staticClass:"column is-6"},[_c('MglMap',{attrs:{"accessToken":_vm.accessToken,"mapStyle":_vm.mapStyle},on:{"load":_vm.onMapLoad,"dragend":_vm.log}},_vm._l((_vm.locations),function(location){return _c('mgl-marker',{key:location.coordinates[0],attrs:{"color":"black","coordinates":location.coordinates},on:{"click":function($event){return _vm.flyTo(location.coordinates)}}})}),1)],1)])])]),_c('div',{staticClass:"columns mb-6"},[_c('div',{staticClass:"column is-10 is-10-mobile is-offset-1 is-offset-1-mobile"},[_c('div',{staticClass:"columns mt-3"},[_c('div',{staticClass:"column is-4-tablet is-12-mobile p-4"},[_c('img',{staticClass:"store-image",attrs:{"src":require('@/assets/images/customer/Enkhuizen.jpg')}})]),_c('div',{staticClass:"column is-4-tablet is-12-mobile p-4"},[_c('img',{staticClass:"store-image",attrs:{"src":require('@/assets/images/customer/Hoogkarspel.jpg')}})]),_c('div',{staticClass:"column is-4-tablet is-12-mobile p-4"},[_c('img',{staticClass:"store-image",attrs:{"src":require('@/assets/images/customer/Hem.jpg')}})])])])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }